import { Button, Col, ConfigProvider, Form, Input, Layout, Row, Typography, DatePicker, message, notification, Space } from 'antd'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import LoadingFullScreen from '../../components/LoadingFullScrren'
import { axiosReq } from '../../config/axios'
import { useDispatch } from 'react-redux'
import { setData } from '../../context/dataSlice'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

const SelfInvoicingFromOrder = () => {
    const { teamId } = useParams()
    const [loading, setLoading] = useState(true)
    const [submitting, setSubmitting] = useState(false)
    const [error, setError] = useState(null)
    const [teamData, setTeamData] = useState(null)
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [form] = Form.useForm()

    const retrieveTeamData = async () => {
        try {
            const response = await axiosReq.post('customerportalapp/v1/context/team', {
                id: teamId,
            })

            dispatch(setData({ item: 'brand', data: response.data?.brand }))
            setTeamData(response.data)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setError(error?.response?.data?.message ?? 'Ha ocurrido un error al cargar los datos del portal')
            notification.error({
                message: 'Error',
                description: 'No se pudo cargar la información del portal',
                duration: 5
            })
        }
    }

    useEffect(() => {
        if (teamId) {
            retrieveTeamData()
        }
    }, [teamId])

    const onFinish = async (values) => {
        setSubmitting(true)
        try {
            const response = await axiosReq.post('resolveReceiptDataSeats', {
                orderId: values.orderId,
                amount: parseFloat(values.amount) * 100,
                date: values.purchaseDate.format('YYYY-MM-DD'),
                type: 'retrieve_from_order_id',
                teamId: teamData?.id
            })

            if (response.data?.id) {
                window.location.href = `/autofactura?id=${response.data.id}`
            } else {
                message.error('No se encontró un recibo con los datos proporcionados')
            }
        } catch (error) {
            notification.error({
                message: 'Error',
                description: error?.response?.data?.message || 'No se encontró un recibo con los datos proporcionados',
                duration: 5
            })
        }
        setSubmitting(false)
    }

    const GetOrderForm = () => {
        return (
            <Row style={{ width: '100%', minHeight: '100vh' }} justify="center" align="middle">
                <Col xs={23} sm={20} md={16} lg={12} xl={8}>
                    <Space direction="vertical" size="large" style={{ width: '100%', textAlign: 'center' }}>
                        {/* Logo Section */}
                        {teamData?.brand?.logo && (
                            <div>
                                <img 
                                    src={teamData.brand.logo} 
                                    alt={teamData.brand.alias} 
                                    style={{ height: '48px', margin: '0 auto' }}
                                />
                            </div>
                        )}

                        {/* Header Section */}
                        <Space direction="vertical" size="small">
                            <Typography.Title level={2} style={{ margin: 0 }}>
                                Portal de facturación
                            </Typography.Title>
                            <Typography.Text type="secondary">
                                Ingresa los datos de tu {t('compra')} para generar tu factura
                            </Typography.Text>
                        </Space>

                        {/* Form Section */}
                        <Form 
                            form={form}
                            layout="vertical" 
                            onFinish={onFinish}
                            style={{ width: '100%', textAlign: 'left' }}
                        >
                            <Form.Item
                                label="Número de orden o ticket"
                                name="orderId"
                                rules={[{ required: true, message: 'Por favor ingresa el número de orden o ticket' }]}
                            >
                                <Input size="large" placeholder="Ej: ORD-123456" />
                            </Form.Item>

                            <Form.Item
                                label="Monto de la compra"
                                name="amount"
                                rules={[
                                    { required: true, message: 'Por favor ingresa el monto de tu compra' },
                                    { pattern: /^\d+(\.\d{1,2})?$/, message: 'Ingresa un monto válido' }
                                ]}
                            >
                                <Input
                                    size="large"
                                    placeholder="0.00" 
                                    type="number" 
                                    step="0.01"
                                />
                            </Form.Item>

                            <Form.Item
                                label="Fecha de compra"
                                name="purchaseDate"
                                rules={[{ required: true, message: 'Por favor selecciona la fecha de compra' }]}
                            >
                                <DatePicker 
                                    size="large"
                                    style={{ width: '100%' }}
                                    format="DD/MM/YYYY"
                                    disabledDate={(current) => current && current > dayjs().endOf('day')}
                                />
                            </Form.Item>

                            <Form.Item style={{ marginTop: '24px' }}>
                                <Button 
                                    type="primary" 
                                    htmlType="submit" 
                                    size="large"
                                    style={{ 
                                        width: '100%',
                                        backgroundColor: teamData?.brand?.primaryColor 
                                    }} 
                                    loading={submitting}
                                >
                                    Enviar
                                </Button>
                            </Form.Item>
                        </Form>

                        {/* Support Section */}
                        {teamData?.supportEmail && (
                            <Typography.Text type="secondary">
                                ¿Necesitas ayuda?{' '}
                                <Typography.Link href={`mailto:${teamData.supportEmail}?subject=Ayuda con facturación`}>
                                    Contáctanos
                                </Typography.Link>
                            </Typography.Text>
                        )}

                        {/* Terms Section */}
                        <Typography.Paragraph type="secondary" style={{ fontSize: '12px', textAlign: 'center' }}>
                            Al continuar, aceptas nuestros{' '}
                            <Typography.Link href="#" style={{ fontSize: '12px' }}>
                                Términos de servicio
                            </Typography.Link>
                            {' '}y{' '}
                            <Typography.Link href="#" style={{ fontSize: '12px' }}>
                                Política de privacidad
                            </Typography.Link>
                        </Typography.Paragraph>

                        {/* Powered by Section */}
                        <Typography.Text type="secondary" style={{ fontSize: '12px' }}>
                            Powered by{' '}
                            <span style={{ fontFamily: 'Russo One', letterSpacing: '0.5px' }}>
                                gigstack
                            </span>
                        </Typography.Text>
                    </Space>
                </Col>
            </Row>
        )
    }


    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: teamData?.brand?.primaryColor ?? '#00b96b',
                    colorLink: teamData?.brand?.primaryColor ?? '#00b96b',
                    colorText: '#333',
                    colorTextSecondary: '#666',
                },
                components: {
                    Input: {
                        controlHeight: 40,
                    },
                },
            }}
        >
            <Layout style={{ 
                padding: 0, 
                margin: 0,
                minHeight: '100vh',
                backgroundColor: '#fff'
            }}>
                <Layout.Content>
                    {loading ? (
                        <LoadingFullScreen loading={true} />
                    ) : error ? (
                        <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
                            <Typography.Text>No se encontró un recibo con los datos proporcionados</Typography.Text>
                        </Row>
                    ) : (
                        <GetOrderForm />
                    )}
                </Layout.Content>
            </Layout>
        </ConfigProvider>
    )
}

export default SelfInvoicingFromOrder 