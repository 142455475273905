import React, { useState } from 'react';
import { Upload, message, Row, Typography, Spin } from 'antd';
import { UploadOutlined, CheckCircleOutlined, WarningOutlined, LoadingOutlined } from '@ant-design/icons';

const FileUpload = ({ onFileUploaded }) => {
    const [files, setFiles] = useState([]);
    const [currentProgress, setCurrentProgress] = useState(0);
    const [elementSuccess, setElementSuccess] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);

    const props = {
        name: 'file',
        multiple: false,
        accept: '.pdf',
        showUploadList: false,
        customRequest: async ({ file, onSuccess, onError }) => {
            setIsProcessing(true);
            try {
                await onFileUploaded(file);
                onSuccess('ok');
            } catch (error) {
                onError(error);
            } finally {
                setIsProcessing(false);
            }
        },
        beforeUpload: (file) => {
            if (file.type !== 'application/pdf') {
                message.error('Solo se permiten archivos PDF');
                return false;
            }
            if (files.length > 0) {
                message.warning('No puedes seleccionar más de un archivo.');
                return false;
            }
            return true;
        },
        onChange(info) {
            const { status } = info.file;
            if (status === 'done') {
                setFiles([info.file]);
                setElementSuccess(true);
                message.success(`${info.file.name} archivo cargado exitosamente`);
            } else if (status === 'error') {
                setElementSuccess(false);
                message.error(`${info.file.name} error al cargar el archivo`);
            }
        },
        onRemove: () => {
            setFiles([]);
            setElementSuccess(false);
            setIsProcessing(false);
        }
    };

    return (
        <div style={{ width: '100%', cursor: 'pointer' }}>
            <Upload {...props} className="csf-upload" style={{ width: '100%' }}>
                <Row
                    align="middle"
                    style={{
                        width: '100%',
                        height: '100%',
                        minHeight: '40px',
                        border: '1px solid  #D8DAE5',
                        borderRadius: '5px',
                    }}
                >
                    <Typography.Text
                        className="p-base-regular"
                        style={{ paddingLeft: '5px', flex: 1, color: 'lightgray', width: '100%' }}
                    >
                        {files.length > 0 ? files[0].name : 'Seleccionar constancia de situación fiscal'}
                    </Typography.Text>

                    <Row
                        align="middle"
                        className="rounded-md"
                        style={{
                            borderLeft: '0px solid #D8DAE5',
                            borderTopLeftRadius: '3px',
                            borderBottomLeftRadius: '3px',
                            backgroundColor: 'var(--neutral-5)',
                            height: '100%',
                            minHeight: '40px',
                            padding: '0px 5px',
                            flexShrink: 0
                        }}
                    >
                        <Typography.Text className="p-base-semi-bold neutral-1" style={{ paddingRight: '5px' }}>
                            {isProcessing ? 'Procesando...' : files.length > 0 ? 'Seleccionado' : 'Subir'}
                        </Typography.Text>
                        {isProcessing ? (
                            <Spin indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />} />
                        ) : currentProgress > 0 && currentProgress < 100 ? (
                            <Typography.Text className="p-base-bold neutral-1">
                                {currentProgress}%
                            </Typography.Text>
                        ) : files.length > 0 ? (
                            elementSuccess ? (
                                <CheckCircleOutlined className="icon" />
                            ) : (
                                <WarningOutlined className="icon" />
                            )
                        ) : (
                            <UploadOutlined className="icon" />
                        )}
                    </Row>
                </Row>
            </Upload>
        </div>
    );
};

export default FileUpload; 