import { usePostHog } from 'posthog-js/react'
import { getItemsAmounts } from '../functions/general'
import { useState } from 'react'
import { invoiceUsage } from '../datasources/Invoice'
import { Col, Form, Row, Image, Typography, Button, Divider, message, Anchor, Table, Alert, Tag, Card, Spin, Progress } from 'antd'
import { CheckCircle, DownloadSimple, Warning, CircleNotch } from '@phosphor-icons/react'
import TryGigstackCard from './TryGigstackCard'
import { InvoiceForm } from './GenerateInvoice'
import { motion } from 'framer-motion'
import { VscLoading } from 'react-icons/vsc'
import { axiosReq } from '../config/axios'
import PowerByGigPrivacy from './PowerdByGigPrivacy'
import TestModeBanner from './TestmodeBanner'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

function GenerateInvoiceV2({ receipt, updateReceipt }) {
    const posthog = usePostHog()
    const {
        supportEmail,
        legal_name,
        country,
        zip,
        id,
        timestamp,
        validUntil,
        currency,
        pdf,
        livemode,
        client,
        items,
        paidIn,
        payment_form: pform,
    } = receipt
    const payment_form = typeof pform === 'string' ? pform : pform?.value

    const [success, setSuccess] = useState(false)

    const itemsAmounts = getItemsAmounts({ items: items, useDiscount: true })
    const { brand } = receipt.user
    const { logo, alias, primaryColor } = brand
    const [modalDemo, setModalDemo] = useState(false)
    const [error, setError] = useState(null)
    const [allowedUsaged, setAllowedUsaged] = useState(invoiceUsage)
    const [creating, setCreating] = useState(false)
    const [state, setState] = useState(null)
    const [downloading, setDownloading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [countryState, setCountryState] = useState(country)
    const [form] = Form.useForm()
    const [fileLinks, setFileLinks] = useState({
        pdfLink: null,
        xmlLink: null,
    })
    const { t } = useTranslation()
    const currencyFormat = Intl.NumberFormat('es-MX', { style: 'currency', currency })

    const handleDownloadPdf = () => {
        const { pdfLink } = fileLinks
        if (pdfLink) {
            const a = document.createElement('a')
            a.target = '_blank'
            a.href = pdfLink
            a.download = `${id}-${state.rfc}.pdf`
            a.click()
            a.remove()
        }
    }

    const handleDownloadXml = () => {
        const { xmlLink } = fileLinks
        if (xmlLink) {
            const a = document.createElement('a')
            a.target = '_blank'
            a.href = xmlLink
            a.download = `${id}-${state.rfc}.xml`
            a.click()
            a.remove()
        }
    }

    const DownloadInvoices = () => {
        return (
            <Row justify="center">
                <Col xs={24}>
                    <Row justify="center">
                        <div
                            className="d-flex flex-row flex-center"
                            style={{
                                width: '100%',
                                padding: '15px',
                                borderRadius: '10px',
                                backgroundColor: primaryColor,
                                cursor: downloading ? 'wait' : 'pointer',
                                marginTop: '15px',
                            }}
                            onClick={() => {
                                handleDownloadPdf()
                            }}
                        >
                            <Row align="middle">
                                <Typography.Text style={{ color: 'white' }}>Descargar {t('factura')} PDF</Typography.Text>
                            </Row>
                        </div>
                        <div
                            className="d-flex flex-row flex-center"
                            style={{
                                width: '100%',
                                padding: '15px',
                                borderRadius: '10px',
                                backgroundColor: primaryColor,
                                cursor: downloading ? 'wait' : 'pointer',
                                marginTop: '15px',
                            }}
                            onClick={() => {
                                handleDownloadXml()
                            }}
                        >
                            <Row align="middle">
                                <Typography.Text style={{ color: 'white' }}>Descargar {t('factura')} XML</Typography.Text>
                            </Row>
                        </div>
                    </Row>
                </Col>
            </Row>
        )
    }

    const handleDownloadInvoice = async () => {
        try {
            const params = new URLSearchParams(window.location.search)
            const id = params.get('id')
            if (loading) return
            setLoading(true)
            const data = await axiosReq.post('resolveReceiptDataSeats', {
                id,
                type: 'retrieve',
                rfc: state.rfc?.toUpperCase(),
            })

            setFileLinks({
                pdfLink: data.data.url[0],
                xmlLink: data.data.urlxml[0],
            })
            setLoading(false)
        } catch (error) {
            // console.log(error)
            setLoading(false)
            message.error(error?.response?.data?.message ?? 'Ocurrió un error')
        }
    }
    const InvoicesSuccessCard = () => {
        const [processingReceipts, setProcessingReceipts] = useState([]);
        const [processedReceipts, setProcessedReceipts] = useState([]);
        const [failedReceipts, setFailedReceipts] = useState([]);
        const [isProcessingAll, setIsProcessingAll] = useState(false);
        const [progress, setProgress] = useState(0);

        const formatCurrency = (amount, currencyCode) => {
            return new Intl.NumberFormat('es-MX', { 
                style: 'currency', 
                currency: currencyCode || 'MXN',
                minimumFractionDigits: 2
            }).format(amount);
        };

        const renderStatus = (status) => {
            const statusColors = {
                'pending': 'orange',
                'completed': 'green',
                'expired': 'red',
                'draft': 'blue'
            };
            
            return (
                <Tag color={statusColors[status] || 'default'} style={{ borderRadius: '12px', fontSize: '12px' }}>
                    {status === 'pending' ? 'Pendiente' : 
                     status === 'completed' ? 'Completado' : 
                     status === 'expired' ? 'Expirado' : 
                     status === 'draft' ? 'Borrador' : status}
                </Tag>
            );
        };

        const getReceiptState = (receiptId) => {
            if (processingReceipts.includes(receiptId)) return 'processing';
            if (processedReceipts.includes(receiptId)) return 'success';
            if (failedReceipts.includes(receiptId)) return 'failed';
            return 'pending';
        };

        const renderActionColumn = (record) => {
            const receiptState = getReceiptState(record.id);
            
            switch (receiptState) {
                case 'processing':
                    return (
                        <Spin 
                            indicator={
                                <CircleNotch 
                                    size={16} 
                                    weight="bold" 
                                    style={{ 
                                        color: primaryColor,
                                        animation: 'spin 1s infinite linear'
                                    }} 
                                />
                            } 
                            tip="Procesando..." 
                        />
                    );
                case 'success':
                    return (
                        <Tag color="success" style={{ borderRadius: '12px' }}>
                            <CheckCircle size={14} weight="bold" style={{ verticalAlign: 'middle', marginRight: '4px' }} />
                            Facturado
                        </Tag>
                    );
                case 'failed':
                    return (
                        <Button 
                            size="small"
                            danger
                            onClick={() => handleInvoiceSingleReceipt(record.id)}
                        >
                            Reintentar
                        </Button>
                    );
                default:
                    return (
                        <Button 
                            size="small"
                            style={{
                                backgroundColor: primaryColor,
                                color: 'white',
                                border: 'none',
                                borderRadius: '6px',
                                boxShadow: '0 2px 0 rgba(0, 0, 0, 0.045)'
                            }}
                            onClick={() => handleInvoiceSingleReceipt(record.id)}
                        >
                            Facturar
                        </Button>
                    );
            }
        };

        const handleInvoiceSingleReceipt = async (receiptId) => {
            try {
                setProcessingReceipts(prev => [...prev, receiptId]);
                
                await axiosReq.post('resolveReceiptDataSeats', {
                    id: receiptId,
                    type: 'create',
                    rfc: state.rfc?.toUpperCase(),
                    client: state.client,
                    use: state.use,
                    tax_system: state.tax_system,
                    payment_form: state.payment_form
                });
                
                setProcessingReceipts(prev => prev.filter(id => id !== receiptId));
                setProcessedReceipts(prev => [...prev, receiptId]);
                setFailedReceipts(prev => prev.filter(id => id !== receiptId));
                
                message.success('Factura generada correctamente');
                
                if (!isProcessingAll) {
                    setState({
                        ...state,
                        pendingReceipts: state.pendingReceipts.filter(receipt => 
                            receipt.id !== receiptId && !processedReceipts.includes(receipt.id)
                        )
                    });
                }
            } catch (error) {
                setProcessingReceipts(prev => prev.filter(id => id !== receiptId));
                setFailedReceipts(prev => [...prev, receiptId]);
                message.error(error?.response?.data?.message ?? 'Error al generar la factura');
            }
        };

        const handleInvoiceAllPendingReceipts = async () => {
            try {
                if (isProcessingAll) return;
                
                setIsProcessingAll(true);
                setProgress(0);
                
                const receiptIds = state.pendingReceipts
                    .filter(receipt => 
                        !processedReceipts.includes(receipt.id) && 
                        !processingReceipts.includes(receipt.id)
                    )
                    .map(receipt => receipt.id);
                
                const totalReceipts = receiptIds.length;
                let processed = 0;
                
                for (const receiptId of receiptIds) {
                    try {
                        setProcessingReceipts(prev => [...prev, receiptId]);
                        
                        const r = await axiosReq.post('resolveReceiptDataSeats', {
                            id: receiptId,
                            type: 'create',
                            rfc: state.rfc?.toUpperCase(),
                            client: state.client,
                            use: state.use,
                            tax_system: state.tax_system,
                            payment_form: state.payment_form
                        });

                        if(r.status === 200) {
                            setProcessingReceipts(prev => prev.filter(id => id !== receiptId));
                            setProcessedReceipts(prev => [...prev, receiptId]);
                            
                            processed++;
                            setProgress(Math.floor((processed / totalReceipts) * 100));
                        } else {
                            setProcessingReceipts(prev => prev.filter(id => id !== receiptId));
                            setFailedReceipts([...failedReceipts, receiptId])
                        }
                        
                    } catch (error) {
                        setProcessingReceipts(prev => prev.filter(id => id !== receiptId));
                        setFailedReceipts([...failedReceipts, receiptId])
                    }
                }
                
                setIsProcessingAll(false);
                
                const successCount = receiptIds.length - failedReceipts.length;
                
                if (successCount === receiptIds.length) {
                    message.success('Todas las facturas fueron generadas correctamente');
                } else if (successCount > 0) {
                    message.warning(`${successCount} de ${receiptIds.length} facturas fueron generadas correctamente`);
                } else {
                    message.error('No se pudo generar ninguna factura');
                }
                
            } catch (error) {
                setIsProcessingAll(false);
                message.error('Error al procesar las facturas');
            }
        };

        const allProcessed = state?.pendingReceipts?.every(receipt => 
            processedReceipts.includes(receipt.id)
        );

        const anyProcessing = processingReceipts.length > 0;

        return (
            <motion.div initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.8 }}>
                <Row style={{ width: '100%' }} gutter={{ xs: 5, md: 10, lg: 12 }} justify="center">
                    <Col xs={24}>
                        <Row justify="center">
                            <Image
                                src={logo}
                                alt={alias}
                                width={80}
                                preview={false}
                                style={{
                                    display: window.innerWidth < 768 ? 'none' : 'block',
                                }}
                            />
                        </Row>
                        <Row justify="center" style={{ marginTop: '15px' }}>
                            <Typography.Title level={4} className="h5-bold neutral-1">
                                {t('Tu factura está lista')}
                            </Typography.Title>
                        </Row>
                        
                        {state && state.pendingReceipts && state.pendingReceipts.length > 0 && (
                            <motion.div
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ delay: 0.2, duration: 0.6 }}
                            >
                                <Card
                                    style={{ 
                                        marginTop: '20px',
                                        borderRadius: '12px',
                                        border: '1px solid #FFE58F',
                                        backgroundColor: '#FFFBE6'
                                    }}
                                    bodyStyle={{ padding: '16px' }}
                                >
                                    <Row align="middle" style={{ marginBottom: '15px' }}>
                                        <Warning size={20} weight="fill" color="#FAAD14" style={{ marginRight: '10px' }} />
                                        <Typography.Text strong style={{ color: '#5C5C5C' }}>
                                            Tienes {state.pendingReceipts.filter(r => !processedReceipts.includes(r.id)).length} {state.pendingReceipts.filter(r => !processedReceipts.includes(r.id)).length === 1 ? 'recibo pendiente' : 'recibos pendientes'} por facturar
                                        </Typography.Text>
                                    </Row>
                                    
                                    {isProcessingAll && (
                                        <Row style={{ marginBottom: '15px' }}>
                                            <Col span={24}>
                                                <Progress 
                                                    percent={progress} 
                                                    status="active" 
                                                    size="small" 
                                                    strokeColor={primaryColor} 
                                                />
                                                <Typography.Text type="secondary" style={{ fontSize: '12px', display: 'block', textAlign: 'center', marginTop: '5px' }}>
                                                    Procesando facturas...
                                                </Typography.Text>
                                            </Col>
                                        </Row>
                                    )}
                                    
                                    <Table
                                        dataSource={state.pendingReceipts}
                                        pagination={false}
                                        rowKey="id"
                                        size="small"
                                        style={{ marginBottom: '15px' }}
                                        bordered={false}
                                        className="receipts-table"
                                        rowClassName={(record) => {
                                            const state = getReceiptState(record.id);
                                            return state === 'success' ? 'receipt-processed' : '';
                                        }}
                                        columns={[
                                            {
                                                title: 'ID',
                                                dataIndex: 'id',
                                                key: 'id',
                                                render: (text) => (
                                                    <Typography.Text className="p-small-regular neutral-1" copyable={{ text }}>
                                                        {text.substring(0, 8)}...
                                                    </Typography.Text>
                                                ),
                                            },
                                            {
                                                title: 'Total',
                                                dataIndex: 'total',
                                                key: 'total',
                                                render: (text, record) => (
                                                    <Typography.Text className="p-small-regular neutral-1" strong>
                                                        {formatCurrency(text, record.currency)}
                                                    </Typography.Text>
                                                ),
                                            },
                                            {
                                                title: 'Fecha de emisión',
                                                dataIndex: 'timestamp',
                                                key: 'timestamp',
                                                align: 'end',
                                                render: (text) => (
                                                    <Typography.Text className="p-small-regular neutral-1">
                                                        {moment(text).format('DD/MM/YYYY')}
                                                    </Typography.Text>
                                                ),
                                            },
                                            {
                                                title: 'Válido hasta',
                                                dataIndex: 'validUntil',
                                                key: 'validUntil',
                                                render: (text) => (
                                                    <Typography.Text className="p-small-regular neutral-1">
                                                        {moment(text).format('DD/MM/YYYY')}
                                                    </Typography.Text>
                                                ),
                                            },
                                            {
                                                title: 'Acción',
                                                key: 'action',
                                                align: 'center',
                                                render: (_, record) => renderActionColumn(record)
                                            },
                                        ]}
                                    />
                                    
                                    <Row justify="center">
                                        <Button
                                            style={{
                                                backgroundColor: 'white',
                                                color: primaryColor,
                                                border: `1px solid ${primaryColor}`,
                                                borderRadius: '10px',
                                                fontWeight: 'bold',
                                                boxShadow: '0 2px 0 rgba(0, 0, 0, 0.015)'
                                            }}
                                            icon={<CheckCircle size={16} style={{ marginRight: '5px', verticalAlign: 'text-bottom' }} />}
                                            onClick={handleInvoiceAllPendingReceipts}
                                            loading={isProcessingAll}
                                            disabled={allProcessed || anyProcessing}
                                        >
                                            {isProcessingAll ? 'Procesando...' : 'Emitir todas las facturas pendientes'}
                                        </Button>
                                    </Row>
                                </Card>
                                <Divider style={{ margin: '20px 0' }} />
                            </motion.div>
                        )}
                        
                        {fileLinks.pdfLink === null && fileLinks.xmlLink === null && (
                            <div
                                className="d-flex flex-row flex-center"
                                style={{
                                    width: '100%',
                                    padding: '15px',
                                    borderRadius: '10px',
                                    backgroundColor: primaryColor,
                                    cursor: downloading ? 'wait' : 'pointer',
                                    height: '40px',
                                    marginTop: '15px',
                                }}
                                onClick={() => {
                                    handleDownloadInvoice()
                                }}
                            >
                                <Row align="middle">
                                    {downloading ? (
                                        <VscLoading
                                            style={{
                                                animation: 'spin 1s infinite linear',
                                                marginRight: '14px',
                                                color: primaryColor,
                                            }}
                                        />
                                    ) : (
                                        <>
                                            <Typography.Text style={{ color: 'white' }}>
                                                Descargar .xml y pdf
                                            </Typography.Text>
                                            <DownloadSimple
                                                style={{ marginLeft: '14px', color: 'white', fontSize: '18px' }}
                                            />
                                        </>
                                    )}
                                </Row>
                            </div>
                        )}
                        <Divider />
                        <Row justify="center">
                            <TryGigstackCard type="autoFactura" />
                            <PowerByGigPrivacy />
                        </Row>

                        {fileLinks.pdfLink !== null && fileLinks.xmlLink !== null && (
                            <Row justify="center" style={{ marginTop: '15px' }}>
                                <DownloadInvoices />
                            </Row>
                        )}
                    </Col>
                </Row>
            </motion.div>
        )
    }

    const handleInvoicePendingReceipts = async (receiptIds) => {
        try {
            setLoading(true)
            const isMultiple = receiptIds.length > 1;
            
            await axiosReq.post('resolveReceiptDataSeats', {
                ids: receiptIds,
                type: 'createPending',
                rfc: state.rfc?.toUpperCase(),
                client: state.client,
                use: state.use,
                tax_system: state.tax_system,
                payment_form: state.payment_form
            })
            
            message.success(isMultiple ? 'Facturas generadas correctamente' : 'Factura generada correctamente')
            
            const updatedPendingReceipts = isMultiple 
                ? []
                : state.pendingReceipts.filter(receipt => !receiptIds.includes(receipt.id));
            
            setState({
                ...state,
                pendingReceipts: updatedPendingReceipts
            })
            
            setLoading(false)
        } catch (error) {
            setLoading(false)
            message.error(error?.response?.data?.message ?? 'Ocurrió un error al generar la(s) factura(s)')
        }
    }

    return (
        <>
            {!livemode && <TestModeBanner />}
            {success ? (
                <Row style={{ width: '100%' }} justify="center">
                    <Col xs={23} md={20} lg={12} xl={10} xxl={8}>
                        <InvoicesSuccessCard />
                    </Col>
                </Row>
            ) : (
                <Row justify="center" style={{ width: '100%', minHeight: '100vh', padding: 0, margin: 0 }}>
                    <Col xs={24} lg={0}>
                        <div
                            className="d-flex flex-column"
                            style={{ justifyItem: 'start', alignItems: 'start', padding: '40px 5%', width: '100%' }}
                        >
                            <Row justify="center" style={{ width: '100%' }}>
                                <Image src={logo} alt={alias} width={100} preview={false} />
                            </Row>
                            <Typography.Text
                                style={{
                                    fontSize: '25px',
                                    marginTop: '10px',
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    width: '100%',
                                }}
                            >
                                {itemsAmounts?.totalString} {currency?.toUpperCase()}
                            </Typography.Text>
                            <Row justify="center" style={{ width: '100%' }}>
                                <PowerByGigPrivacy />
                            </Row>
                        </div>
                    </Col>
                    <Col xs={24} md={12} lg={12} style={{ backgroundColor: 'white', padding: '50px 5%' }}>
                        <div className="d-flex flex-column">
                            <InvoiceForm
                                receipt={receipt}
                                version={2}
                                onSuccess={(s) => {
                                    setSuccess(true)
                                    setState(s)
                                }}
                            />
                        </div>
                    </Col>
                    <Col xs={24} md={12} lg={12} style={{ backgroundColor: '', padding: '30px 9%' }}>
                        <div className="d-flex flex-column" style={{ justifyItem: 'start', alignItems: 'start' }}>
                            <Image
                                src={logo}
                                alt={alias}
                                width={80}
                                preview={false}
                                // hide on xs
                                style={{
                                    display: window.innerWidth < 768 ? 'none' : 'block',
                                }}
                            />
                            <Typography.Text className="h6-regular neutral-1" style={{ marginTop: '22px' }}>
                                {t('Total pagado')}:
                            </Typography.Text>
                            <Typography.Text className="h3-bold neutral-1" style={{ marginTop: '10px' }}>
                                {currency?.toUpperCase()} {itemsAmounts?.totalString}
                            </Typography.Text>
                            <Button
                                onClick={() => {
                                    window.open(pdf)
                                }}
                                block
                                ghost
                                size="large"
                                style={{ marginTop: '20px', backgroundColor: 'white', color: 'black' }}
                                icon={<DownloadSimple size={15} />}
                            >
                                Descargar recibo
                            </Button>
                            <Typography.Text
                                className="h6-regular neutral-1"
                                style={{ marginTop: '22px', marginBottom: '15px' }}
                            >
                                Productos o servicios:
                            </Typography.Text>
                            {items.map((item, index) => (
                                <Row key={index} gutter={20} style={{ padding: 0, margin: 0, width: '100%' }}>
                                    <Typography.Text
                                        className="p-base-regular neutral-1"
                                        style={{ marginRight: '15px' }}
                                    >
                                        {item.quantity}
                                    </Typography.Text>
                                    <Typography.Text className="p-base-regular neutral-1" style={{ flex: 1 }}>
                                        {item.name}
                                    </Typography.Text>
                                    <Typography.Text
                                        className="p-base-regular neutral-1"
                                        style={{
                                            textAlign: 'end',
                                            width: 'auto',
                                            marginLeft: '10px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {currency?.toUpperCase()} {currencyFormat.format(item.total)}
                                    </Typography.Text>
                                </Row>
                            ))}
                            <Divider style={{ margin: '20px 0' }} />
                            <Row justify="end" style={{ width: '100%' }}>
                                <div className="d-flex flex-column">
                                    {!receipt.hideTaxes && (
                                        <Row justify="space-between">
                                            <Typography.Text
                                                className="p-base-regular"
                                                style={{ marginRight: '25px', fontWeight: 'bold', textAlign: 'start' }}
                                            >
                                                Subtotal
                                            </Typography.Text>
                                            <Typography.Text className="p-base-regular" style={{ textAlign: 'end' }}>
                                                {currency?.toUpperCase()}{' '}
                                                {currencyFormat.format(itemsAmounts?.subtotal)}
                                            </Typography.Text>
                                        </Row>
                                    )}
                                    {!receipt.hideTaxes && (
                                        <Row justify="space-between" style={{ marginTop: '8px' }}>
                                            <Typography.Text
                                                className="p-base-regular"
                                                style={{ marginRight: '25px', fontWeight: 'bold', textAlign: 'start' }}
                                            >
                                                IVA
                                            </Typography.Text>
                                            <Typography.Text className="p-base-regular" style={{ textAlign: 'end' }}>
                                                {currency?.toUpperCase()} {currencyFormat.format(itemsAmounts?.taxes)}
                                            </Typography.Text>
                                        </Row>
                                    )}
                                    <Row justify="space-between" style={{ marginTop: '8px' }}>
                                        <Typography.Text
                                            className="p-base-regular"
                                            style={{ marginRight: '25px', fontWeight: 'bold', textAlign: 'center' }}
                                        >
                                            Total
                                        </Typography.Text>
                                        <Typography.Text
                                            className="p-base-regular"
                                            style={{ textAlign: 'end', fontWeight: 'bold' }}
                                        >
                                            {currency?.toUpperCase()} {currencyFormat.format(itemsAmounts?.total)}
                                        </Typography.Text>
                                    </Row>
                                </div>
                            </Row>
                            <TryGigstackCard type="autoFactura" hidePBG={receipt.user.brand?.hidePBG} />
                            <Row justify="center" style={{ width: '100%' }}>
                                <PowerByGigPrivacy />
                            </Row>
                        </div>
                    </Col>
                </Row>
            )}
        </>
    )
}

export default GenerateInvoiceV2

