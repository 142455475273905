import { Routes, Route } from 'react-router-dom'
import './App.css'
import LoadingFullScreen from './components/LoadingFullScrren'

import Autofactura from './app/pages/Autofactura'
import Pay from './app/pages/Pay'
import ReactGA from 'react-ga'
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import GetInvoice from './app/pages/GetInvoice'
import { useEffect } from 'react'
import { PaymentLinks } from './app/pages/PaymentLinks'
import MetaPixel from './Marketing/MetaPixel'
import { SupportWidget } from '@disruptive-learning/pulsar/ui'
import withI18n from './interface_communication/i18n'
import SelfInvoicingFromOrder from './app/pages/SelfInvoicingFromOrder'

ReactGA.initialize([
    {
        trackingId: 'G-MWNZRWN70Z',
    },
])

const PageLayout = ({ children }) => (
    <>
        <SupportWidget websiteToken='XJE1RXHHe5CQDfHAiyTYpMUz' />
        {children}
    </>
)

function App() {
    useEffect(() => {
        posthog.init('phc_ud0yyxYqNQb05H9wNaTFcFcLPSJfLHzun1E9jIvV74f', {
            api_host: 'https://app.posthog.com',
            opt_in_site_apps: true,
        })
    }, [])

    return (
        <PostHogProvider client={posthog}>
            <MetaPixel />
            <Routes>
                <Route path="/" element={<LoadingFullScreen />} />
                <Route path="/pay/:id" element={<PageLayout><Pay /></PageLayout>} />
                <Route path="/paymentLinks/:id" element={<PageLayout><PaymentLinks /></PageLayout>} />
                <Route path="/autofactura" element={<PageLayout><Autofactura /></PageLayout>} />
                <Route path="/invoice" element={<PageLayout><GetInvoice /></PageLayout>} />
                <Route path="/:teamId/facturacion" element={<PageLayout><SelfInvoicingFromOrder /></PageLayout>} />
            </Routes>
        </PostHogProvider>
    )
}

export default withI18n(App)
